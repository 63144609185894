document.addEventListener("DOMContentLoaded", () => {
        if (document.querySelector('iframe')) {
            const iframeNodes = document.querySelectorAll('iframe'),
                iframesElements = Array.prototype.slice.call(iframeNodes, 0);

            iframesElements.map((iframeElement, index) => {
                const dataType = iframeElement.getAttribute('data-type')
                iframeParentElement = iframeElement.parentElement;

                if ((dataType === 'vimeo') || (dataType === 'youtube')) {
                    iframeParentElement.classList.add('responsive-video');
                }
            });
        }
});