import { domReady } from '@roots/sage/client';
import './components/header';
import './components/accordion';
import './components/mobile-nav';
import './components/filter';
import './components/link-scroll';
import './components/sticky-sidebar';
import './components/responsive-video';
import './components/select';
import './components/contact-form';
import './components/search';
import './components/searchandfilter';

/**
 * app.main
 */
const main = async (err) => {
    if (err) {
        // handle hmr errors
        console.error(err);
    }
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
