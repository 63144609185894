var header = document.querySelector("header");
var lastScrollTop;

navbar = document.getElementById('navbar');
window.addEventListener('scroll', function () {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (window.pageYOffset > 20) {
        header.classList.add("affixed");
    } else {
        header.classList.remove("affixed");
    }

    if (window.pageYOffset > 200) {
        if (scrollTop < lastScrollTop) {
            header.classList.remove("scrolled-down");
            header.classList.add("scrolled-up");
        } else {
            header.classList.remove("scrolled-up");
            header.classList.add("scrolled-down");
        }
    }

    lastScrollTop = scrollTop;
});

var isMobileVersion = document.getElementsByClassName('mega-menu');

if (isMobileVersion.length > 0) {

    var elements = document.querySelectorAll("li.dropdown.mega-menu");

    var myFunction = function() {
        if (window.pageYOffset > 20) {
            header.classList.add("affixed");
        } else {
            header.classList.add("affixed-top");
        }
    };

    var myFunctionM = function() {
        if (window.pageYOffset > 20) {
            header.classList.add("affixed");
        } else {
            header.classList.remove("affixed-top");
        }
    };

    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mouseover', myFunction, false);
        elements[i].addEventListener('mouseout', myFunctionM, false);
    }
}