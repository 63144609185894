import StickySidebar from "sticky-sidebar"

if( window.innerWidth > 992 ) {
    if (document.querySelector('.sticky-sidebar')) {
        var sidebar = new StickySidebar('.sticky-sidebar', {
            topSpacing: 120,
            bottomSpacing: 120,
            containerSelector: '.sticky-wrapper',
            innerWrapperSelector: '.gb-inside-container'
        });
    }
}
