// Import CustomSelect
import CustomSelect from 'vanilla-js-dropdown';

// Boom!
let selectDropdowns = document.querySelectorAll('select');

selectDropdowns.forEach((dropdown) => {
    new CustomSelect({
        elem: dropdown
    });
});