import 'mmenu-light';

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new MmenuLight(
            document.querySelector("#menu")
        );

        const navigator = menu.navigation(
            {
                'slidingSubmenus': false
            }
        );
        const drawer = menu.offcanvas({
            'position': "right"
        });

        document.querySelector('a[href="#menu"]')
            .addEventListener('click', (evnt) => {
                evnt.preventDefault();

                const div = document.querySelector('body');

                if (div.classList.contains('mm-ocd-opened')) {
                    drawer.close();
                } else {
                    drawer.open();
                }
            });

        document.querySelector('.children a')
            .addEventListener('click', (evnt) => {
                evnt.preventDefault();

                const div = document.querySelector('body');

                if (div.classList.contains('mm-ocd-opened')) {
                    drawer.close();
                } else {
                    drawer.open();
                }
            });
    }
);
