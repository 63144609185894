if (jQuery(".sticky.search").length) {
    jQuery(window).scroll(function () {
        var scroll = jQuery(window).scrollTop();
        var headerHeight = jQuery(".sticky.search").offset().top - jQuery("header").outerHeight() + "px";

        if (scroll >= 564) {
            jQuery('.sticky.search').addClass("fixed");
        } else {
            jQuery('.sticky.search').removeClass("fixed");
        }
    });

}

var params = new URLSearchParams(window.location.search);
if (params) {
    for (let p of params) {
        if (jQuery(document).find("li[data-sf-field-name='" + p[0] + "']")) {
            jQuery(document).find("li[data-sf-field-name='" + p[0] + "'] h4").html(p[1]);
        }

        if (p[0] === 'guests') {
            jQuery(document).find("li[data-sf-field-name='_sfm_rooms'] h4").html(p[1]);
            jQuery(document).find("li[data-sf-field-name='_sfm_rooms'] input[value='" + p[1] + "']").attr('checked', true);
        }
    }
}


jQuery(document).on("sf:ajaxstart", ".searchandfilter", function () {
    //so load your lightbox or JS scripts here again
});

//detects when the ajax request has finished and the content has been updated
// - add scripts that apply to your results here
jQuery(document).on("sf:ajaxfinish", ".searchandfilter", function () {

    var params = new URLSearchParams(window.location.search)
    for (let p of params) {
        if (jQuery(document).find("li[data-sf-field-name='" + p[0] + "']")) {
            jQuery(document).find("li[data-sf-field-name='" + p[0] + "'] h4").html(p[1]);
        }
    }
    //so load your lightbox or JS scripts here again
});


let startmin = jQuery(".sf-field-post-meta-price h4").parent().find('.sf-meta-range').data("start-min");
let startmax = jQuery(".sf-field-post-meta-price h4").parent().find('.sf-meta-range').data("start-max");

jQuery(document).on('click', function(e) {
    var container = jQuery(".searchandfilter ul li");
    if (!jQuery(e.target).closest(container).length) {
        container.removeClass('open');
        container.parent().parent().removeClass('open');
    }
});

var appended = false;
jQuery(".searchandfilter ul").on('click', 'li', function() {
    jQuery(".searchandfilter ul li").removeClass('open');

    if(jQuery(jQuery(this)).hasClass('open')) {
        jQuery(jQuery(this)).removeClass('open');
    } else {
        jQuery(jQuery(this)).addClass('open');
    }
});
