/**
 * Accordion
 */
if (document.querySelector('.accordion-wrapper')) {
    var wrapper = document.querySelector('.accordion-wrapper'),
        accordionToggle = wrapper.querySelector('menu'),
        accordionContentItems = wrapper.querySelector('nav > *'),
        setAccordionWrapperData = wrapper.setAttribute('data-content', 'inactive'),
        rootElement = document.querySelector(':root'),
        accordionContentHeight = accordionContentItems.clientHeight + 20,
        setCssVariableMaxHeight = rootElement.style.setProperty('--accordion-content-max-height', accordionContentHeight + 'px');

    /**
     * Handle Toggle Content
     */
    const handleToggleContent = () => {
        if (wrapper.getAttribute('data-content') === 'active') {
            wrapper.setAttribute('data-content', 'inactive');
        } else if (wrapper.getAttribute('data-content') === 'inactive') {
            wrapper.setAttribute('data-content', 'active');
        }
    }

    accordionToggle.addEventListener("click", handleToggleContent)
}