import Autocomplete from "@trevoreyre/autocomplete-js";

const wikiUrl = '/wp-json/autocomplete/search'

var myEle = document.getElementById("autocomplete");

if(myEle) {
    new Autocomplete('#autocomplete', {
        search: input => {
            const url = `${wikiUrl}?term=${encodeURI(input)}`

            return new Promise(resolve => {

                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        resolve(data)
                    })
            })
        },
        getResultValue: result => result.label,

        onSubmit: result => {
            window.open(`${
                encodeURI(result.permalink)
            }`)
        },
        autoSelect: true
    })
}
